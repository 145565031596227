<template>
  <b-col>
    <b-card class="my-3 mb-2">
      <div class="mb-3">
        <span v-for="email in emails" :key="email">{{email}}; </span>
      </div>
    </b-card>
  </b-col>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'ReportingmiEmails',
  components: {
  },
  computed: {
    rowsShow () {
      return this.$store.state.rowsShow
    }
  },
  created: function () {
    const emails = this.rowsShow.map(x => x.created_by)
    _.pull(emails, '', null)
    this.emails = _.uniq(emails)
  },
  data () {
    const data = {
      emails: []
    }
    return data
  },
  methods: {
  }
}
</script>

<style>
</style>
